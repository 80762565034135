<template>
  <div class="content">
    <app-cells position="start">
      <router-link
        :to="{ name: 'profile-other' }"
        class="btn btn--link"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Редактирование прочей информации</h1>
    </app-cells>
    <form @submit.prevent="onSendForm">
      <app-grid>
        <template #item-1>
          <app-form-group label="Размер одежды">
            <v-select
              :options="clothing_size_options"
              v-model="form.clothing_size"
              :searchable="false"
              class="select"
              placeholder="Выберите значение"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.label }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.label }}</div>
              </template>
            </v-select>
          </app-form-group>
          <app-form-group label="Размер обуви">
            <v-select
              :options="shoe_size_options"
              v-model="form.shoe_size"
              :searchable="false"
              class="select"
              placeholder="Выберите значение"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.label }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.label }}</div>
              </template>
            </v-select>
          </app-form-group>
          <app-form-group label="Статус">
            <v-select
              label="name"
              :options="status_options"
              v-model="form.status"
              :reduce="status => status.id"
              :searchable="false"
              class="select"
              placeholder="Выберите значение"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
            </v-select>
          </app-form-group>
          <app-form-group>
            <label class="checkbox">
              <div class="checkbox__text">
                Показать профиль в публичной части
              </div>
              <input type="checkbox" name="is_public" v-model="form.is_public" />
              <div class="checkbox__checkmark"></div>
            </label>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label="Семейное положение">
            <app-cells position="start">
              <app-cells position="start" :indent="false">
                <app-radio
                  v-model="form.marital"
                  label="холост / не замужем"
                  :value="true"
                  name="marital"
                  :checked="form.marital"
                />
                <app-radio
                  v-model="form.marital"
                  label="женат / замужем"
                  :value="false"
                  name="marital"
                  :checked="form.marital"
                />
              </app-cells>
            </app-cells>
          </app-form-group>
          <app-form-group label-for="about" label="О себе">
            <app-textarea
              v-model.trim="form.about"
              id="about"
              placeholder="Напишите немного о себе"
            />
          </app-form-group>
          <app-form-group label="Являетесь ли вы участником Программы «Российская студенческая весна»?">
            <app-cells position="start" :indent="false">
              <label class="radio">
                <span class="radio__text">да</span>
                <input
                  v-model.number="form.is_studvesna_member"
                  type="radio"
                  :value="true"
                />
                <span class="radio__radiomark"></span>
              </label>
              <label class="radio">
                <span class="radio__text">нет</span>
                <input
                  v-model.number="form.is_studvesna_member"
                  type="radio"
                  :value="false"
                />
                <span class="radio__radiomark"></span>
              </label>
            </app-cells>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button ref="submit">Сохранить</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ProfileOtherEdit',
  data() {
    return {
      form: {
        marital: false
      },
      clothing_size_options: [38, 40, 42, 44, 46, 48, 50, 52, 54],
      shoe_size_options: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
      status_options: []
    }
  },
  mounted() {
    this.$store.dispatch('profile/other/GET_DATA')
      .then(response => { this.form = response.data })
    this.$store.dispatch('dict/GET_USER_STATUS')
      .then(response => { this.status_options = response.data })
  },
  methods: {
    onSendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('profile/other/PATCH_DATA', this.form)
        .then(() => {
          this.$refs.submit.preload = false
          this.$router.push({ name: 'profile-other' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Данные успешно сохранены'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  },
}
</script>
